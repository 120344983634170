.mobile-block {
  display: none;
}

.btn-mobile {
  display: none;
}

@media (max-width: 1200px) {
  /* tablet devices */
  .main-wrap {
    margin-left: 220px;
  }

  .navbar-aside {
    max-width: 220px;
  }
}
@media (max-width: 992px) {
  /* small tablet devices */
  .itemlist .col-price {
    text-align: right;
  }
  .itemlist .col-check {
    display: none;
  }

  .card-header .col-check {
    display: none;
  }
}
@media all and (max-width: 1200px) {
  /* mobile devices */
  .mobile-block {
    display: block !important;
  }

  .mobile-hide {
    display: none !important;
  }

  .btn-mobile {
    display: inline-block !important;
  }

  .navbar-aside {
    z-index: 900;
    transform: translateX(-100%);
    overflow-x: hidden;
    transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
  }
  .navbar-aside .aside-top .logo {
    height: 40px;
  }

  .navbar-aside.show {
    visibility: visible;
    transform: translateX(0);
  }

  .mobile-offcanvas {
    visibility: hidden;
    transform: translateX(-100%);
    border-radius: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1200;
    width: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
  }

  .mobile-offcanvas.show {
    visibility: visible;
    transform: translateX(0);
  }

  .main-header {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .main-header .col-nav {
    order: 1;
    width: 100%;
    margin-bottom: 0.8rem;
    margin-left: auto;
  }
  .main-header .col-search {
    flex-grow: 1;
    width: 100%;
    order: 2;
  }

  .main-wrap {
    margin-left: 0 !important;
  }

  .content-header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  .content-header .content-title {
    margin-bottom: 1rem;
  }

  .card-header input.form-control {
    margin-bottom: 1rem;
  }

  .order-info-wrap .icontext {
    margin-bottom: 1rem;
  }

  .table-responsive table {
    min-width: 700px;
  }
}
